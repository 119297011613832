


















































































import Vue from "vue";
import LoadingErrorable from "@/models/util/LoadingErrorable";
import IJob from "@/models/jobs/IJob";
import JobProvider from "@/api/JobProvider";

import SpriteIcon from "@/components/util/SpriteIcon.vue";
import Loader from "@/components/util/Loader.vue";
import JobStatScaling from "@/components/job/JobStatScaling.vue";
import UiString from "@/components/uistring/UiString.vue";
import SkillTree from "@/components/skill/SkillTree.vue";
import JobTree from "./JobTree.vue";

interface IData {
    job: LoadingErrorable<IJob, any>;
}

export default Vue.extend({
    components: {
        Loader,
        SpriteIcon,
        JobStatScaling,
        UiString,
        SkillTree,
        JobTree,
    },
    props: {
        "jobId": {}
    },
    data(): IData {
        return {
            job: new LoadingErrorable(),
        };
    },
    watch: {
        jobId(newId, oldId) {
            this.fetchData();
        }
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.job.startLoad();
            JobProvider.getJob(Number(this.jobId), true).then((job) => {
                this.job.done(job);
            }).catch((error: any) => {
                this.job.failed(error);
            });
        },
        getBreadcrumb() {
            let ret = [];
            let j = this.job.value ? this.job.value.parentJob : null;
            while (j) {
                ret.push(j);
                j = j.parentJob;
            }

            return ret.reverse();
        },
        getBaseClassId() {
            if (!this.job.value) {
                return 0;
            }

            let ret = null;
            let j: IJob|null = this.job.value.parentJob || this.job.value;
            while (j) {
                ret = j;
                j = j.parentJob;
            }
            
            return ret ? ret.id : 0;
        },
        getClassType(): string {
            if (this.job.value) {
                const t = this.job.value.classType;
                switch (t) {
                    case 0:
                        return "";
                    case 1:
                        return "dark";
                    case 2:
                        return "light";
                    default:
                        return String(t);
                }
            }

            return "";
        },
    }

});
