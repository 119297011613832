






















































import Vue from 'vue';
import LoadingErrorable from '@/models/util/LoadingErrorable';
import JobProvider from '@/api/JobProvider';
import IJob from '@/models/jobs/IJob';
import Loader from "@/components/util/Loader.vue";
import SpriteIcon from "@/components/util/SpriteIcon.vue";
import UiString from "@/components/uistring/UiString.vue";
import BigErrorBox from "@/components/util/BigErrorBox.vue";

import { axiosErrorToString } from "@/helpers/AxiosErrorUtils";

interface IBaseClass {
    base: IJob;
    firsts: IFirstClass[];
}

interface IFirstClass {
    first: IJob;
    seconds: IJob[];
}

interface IData {
    classes: IBaseClass[];
    jobs: LoadingErrorable<IJob[], any>;
}

export default Vue.extend({
    components: {
        BigErrorBox,
        Loader,
        SpriteIcon,
        UiString,
    },
    props: {
        selectBaseClass: {
            type: Number as () => number,
        },
    },
    data(): IData {
        return {
            classes: [],
            jobs: new LoadingErrorable(),
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.jobs.startLoad();
            JobProvider.getAllJobs().then((all) => {
                this.classes = this.buildTree(all);
                this.jobs.done(all);
                this.$emit("done");
            }, (err) => {
                this.jobs.failed(axiosErrorToString(err));
            });
        },
        buildTree(jobs: IJob[]): IBaseClass[] {
            const baseCls = jobs.filter((j) => j.jobNumber == 0 && (this.selectBaseClass ? j.id === this.selectBaseClass : true));
            let ret: IBaseClass[] = [];
            for (let cls of baseCls) {
                let ifc: IBaseClass = {
                    base: cls,
                    firsts: [],
                };

                const firstJobs = jobs.filter((j) => j.jobNumber == 1 && j.parentJobId == cls.id);
                for (let f of firstJobs) {
                    // placeholder
                    if (f.name.id === 4005462) {
                        continue;
                    }

                    let fc: IFirstClass = {
                        first: f,
                        seconds: jobs.filter((j) => j.jobNumber == 2 && j.parentJobId == f.id && j.name.id !== 4005462), // placeholder
                    };

                    ifc.firsts.push(fc);
                }

                ret.push(ifc);
            }

            return ret;
        }
    },
});
