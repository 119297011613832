












































































import Vue from 'vue';
import LoadingErrorable from '@/models/util/LoadingErrorable';
import { IJobScaling } from '@/models/jobs/IJobScaling';
import JobProvider from '@/api/JobProvider';
import Loader from "@/components/util/Loader.vue";

interface IData {
    scaling: LoadingErrorable<IJobScaling, any>;
}

export default Vue.extend({
    components: {
        Loader,
    },
    props: {
        "jobId": {
            type: Number as () => number,
        },
    },
    data(): IData {
        return {
            scaling: new LoadingErrorable(),
        }
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.scaling.startLoad();
            JobProvider.getJobScaling(this.jobId).then((result) => {
                this.scaling.done(result);
            }).catch((error: any) => {
                this.scaling.failed(error);
            });
        }
    }

});
